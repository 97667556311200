import vehicles from '@/assets/data/vehicle.json';
import { Answer } from '@/types/api/Answer';
import { getQuizJson } from '@/services/json-service';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export async function getAnswerList(
  questionId: number,
  language: string | null = null
): Promise<Answer[]> {
  const config = getQuizJson(language);
  let item = (config as any)[0].questions[0];
  for (const quiz of config as any) {
    const question = quiz.questions.find((item) => item.id === questionId);
    if (question) {
      item = question;
      break;
    }
  }
  return item.options.map((item) => {
    return {
      id: item.id,
      question: questionId,
      title: item.title,
      description: item.description,
      vehicle: item.vehicle,
      correct: item.correct,
      settings: item.vehicle ? { icon: vehicles[item.vehicle].icon } : {},
    };
  });
}
