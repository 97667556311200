import { Question } from '@/types/api/Question';
import { getQuizJson } from '@/services/json-service';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export async function getQuestionList(
  quizId: number,
  language: string | null = null
): Promise<Question[]> {
  const config = getQuizJson(language);
  let item = (config as any).find((item) => item.id === quizId);
  if (!item) item = config[0];
  return item.questions.map((item) => {
    return {
      id: item.id,
      quiz: quizId,
      title: item.title,
      description: item.description,
      hint: item.hint,
      explanation: item.explanation,
    };
  });
}
