<template>
  <div
    :style="{
      '--optionWidth': `${optionWidth}px`,
      '--optionHeight': `${optionHeight}px`,
      '--containerWidth': `${containerWidth}px`,
      '--containerHeight': `${containerHeight}px`,
      '--containerOptionWidth': `${containerOptionWidth}px`,
      '--containerOptionHeight': `${containerOptionHeight}px`,
      '--optionX': `${optionX}px`,
      '--optionY': `${optionY}px`,
      '--containerX': `${animationProps.backgroundPositionX}px`,
      '--containerY': `${containerY}px`,
      '--environmentWidth': `${environmentWidth}px`,
      '--environmentHeight': `${environmentHeight}px`,
      '--storeFactor': storeFactor,
    }"
  >
    <quiz-question
      :question="question"
      :answers="answers"
      :showHint="showHint"
      :showInfo="showInfo"
      :isCorrect="isCorrect"
      :question-no="questionNo"
      :state-list="stateList"
      :can-confirm="canConfirm"
      @confirm="confirm"
    >
      <template #header>
        <div
          ref="canvasContainer"
          class="container-environment"
          :style="{
            backgroundImage: `url(/assets/visualization/${animationConfig.background})`,
          }"
        >
          <Application
            v-if="isContainerSet"
            class="canvas-container"
            ref="pixi"
            :width="environmentWidth"
            :height="environmentHeight"
            :transparent="true"
            :backgroundAlpha="0"
            :style="{
              zIndex: animationConfig.container.foreground ? 1000 : 0,
              pointerEvents: 'none',
            }"
          >
            <sprite
              :texture="
                animationSprite.textures[animationConfig.container.image]
              "
              :height="containerHeight"
              :width="containerWidth"
              :anchor="0"
              :x="animationProps.backgroundPositionX"
              :y="containerY"
            />
            <animated-sprite
              v-if="animationConfig.result"
              ref="approved"
              :textures="
                animationSprite.animations[
                  animationConfig.result.approved.animation
                ]
              "
              :animation-speed="0.1"
              :width="environmentHeight * animationConfig.result.approved.width"
              :height="
                environmentHeight * animationConfig.result.approved.height
              "
              :x="environmentWidth * animationConfig.result.approved.x"
              :y="environmentHeight * animationConfig.result.approved.y"
              :loop="false"
              :playing="isCorrect && showInfo"
              @complete="animationCompleted"
            />
            <animated-sprite
              v-if="animationConfig.result"
              ref="rejected"
              :textures="
                animationSprite.animations[
                  animationConfig.result.rejected.animation
                ]
              "
              :animation-speed="0.1"
              :width="environmentHeight * animationConfig.result.rejected.width"
              :height="
                environmentHeight * animationConfig.result.rejected.height
              "
              :x="environmentWidth * animationConfig.result.rejected.x"
              :y="environmentHeight * animationConfig.result.rejected.y"
              :loop="false"
              :playing="!isCorrect && showInfo"
              @complete="animationCompleted"
            />
          </Application>
          <div class="container">
            <div
              class="container-store-space"
              :class="animationConfig.option.alignment"
            >
              <draggable
                v-model="selectionList"
                item-key="id"
                group="option"
                class="container-store"
                @change="changeContainer"
              >
                <template #item="{ element }">
                  <el-card class="option">
                    <div>
                      <span
                        class="image"
                        :style="{
                          '--color': animationConfig.option.tint
                            ? element.settings.color
                            : 'transparent',
                          '--opacity': animationConfig.option.tint
                            ? '50%'
                            : '100%',
                        }"
                      >
                        <el-tooltip :content="element.title">
                          <img
                            :src="animationImages[element.settings.image]"
                            alt="option"
                          />
                        </el-tooltip>
                      </span>
                    </div>
                    <div class="title">
                      {{ element.title }}
                    </div>
                  </el-card>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </template>
      <template #default>
        <draggable
          v-if="animationSprite"
          v-model="storeList"
          item-key="id"
          group="option"
          class="store"
          :class="animationConfig.option.alignment"
        >
          <template #item="{ element }">
            <el-card class="option">
              <div>
                <span
                  class="image"
                  :style="{
                    '--color': animationConfig.option.tint
                      ? element.settings.color
                      : 'transparent',
                    '--opacity': animationConfig.option.tint ? '50%' : '100%',
                  }"
                >
                  <el-tooltip
                    :content="
                      element.description ? element.description : element.title
                    "
                  >
                    <img
                      :src="animationImages[element.settings.image]"
                      alt="option"
                    />
                  </el-tooltip>
                </span>
              </div>
              <div class="title">
                {{ element.title }}
              </div>
            </el-card>
          </template>
        </draggable>
        <div class="hint">
          {{ $t(`views.quiz.drag.${quiz.topic}`) }}
        </div>
      </template>
    </quiz-question>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Question } from '@/types/api/Question';
import { Answer } from '@/types/api/Answer';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Choice } from '@/types/api/Choice';
import { Quiz } from '@/types/api/Quiz';
import QuizQuestion from '@/components/QuizQuestion.vue';
import { QuestionState } from '@/types/quiz/QuestionState';
import draggable from 'vuedraggable';
import { getRandomColorList } from '@/utils/colors';
import * as pixiUtil from '@/utils/pixi';
import config from '@/assets/data/visualization.json';
import * as PIXI from 'pixi.js';
import { Application } from 'vue3-pixi';
import SpriteCanvas from '@/components/SpriteCanvas.vue';
import * as TWEEDLE from 'tweedle.js';
import { delay, until } from '@/utils/wait';

interface AnimationProps {
  backgroundPositionX: number;
  ready: boolean;
}

@Options({
  components: {
    SpriteCanvas,
    QuizQuestion,
    draggable,
    FontAwesomeIcon,
    Application,
  },
  emits: [
    'confirm',
    'animationCompleted',
    'finalCompleted',
    'update:showAnimation',
  ],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class DragAndDrap extends Vue {
  @Prop() readonly quiz!: Quiz;
  @Prop() readonly question!: Question;
  @Prop({ default: [] }) readonly answers!: Answer[];
  @Prop({ default: [] }) readonly choices!: Choice[];
  @Prop({ default: false }) readonly showHint!: boolean;
  @Prop({ default: false }) readonly showAnimation!: boolean;
  @Prop({ default: false }) readonly showInfo!: boolean;
  @Prop({ default: false }) readonly isCorrect!: boolean;
  @Prop({ default: 0 }) readonly questionNo!: number;
  @Prop({ default: [] }) readonly stateList!: QuestionState[];
  @Prop({ default: false }) readonly canConfirm!: boolean;
  @Prop({ default: false }) readonly animateFinalStep!: boolean;

  storeList: Answer[] = [];
  selectionList: Answer[] = [];
  environmentWidth = 100;
  environmentHeight = 100;
  optionWidth = 100;
  optionHeight = 100;
  optionAspect = 1;
  containerWidth = 100;
  containerHeight = 100;
  textureWidth = 100;
  textureHeight = 100;
  animationSprite: PIXI.Spritesheet | null = null;
  animationImages: { [key: string]: string } = {};
  isContainerSet = false;
  animationProps: AnimationProps = {
    backgroundPositionX: 0,
    ready: true,
  };
  tween: TWEEDLE.Tween<AnimationProps> | null = null;

  get visualizationType() {
    return this.quiz.settings.visualization_type;
  }

  get animationConfig(): any {
    return config[this.visualizationType];
  }

  get containerTexture(): PIXI.Texture | null {
    if (this.animationSprite)
      return this.animationSprite.textures[
        this.animationConfig.container.image
      ];
    return null;
  }

  get backgroundUrl(): string {
    return this.animationConfig.background;
  }

  get containerOptionWidth(): number {
    return this.optionWidth * this.animationConfig.option.totalWidthFactor;
  }

  get containerOptionHeight(): number {
    return this.optionHeight * this.animationConfig.option.totalHeightFactor;
  }

  get environmentAspect(): number {
    return this.environmentWidth / this.environmentHeight;
  }

  get textureAspect(): number {
    return this.textureWidth / this.textureHeight;
  }

  get storeFactor(): number {
    if (this.environmentAspect < 2.95) return 4;
    return 2.1;
  }

  get containerX(): number {
    return (
      (this.environmentWidth - this.containerWidth) *
      this.animationConfig.container.x
    );
  }

  get containerY(): number {
    return (
      (this.environmentHeight - this.containerHeight) *
      this.animationConfig.container.y
    );
  }

  get optionX(): number {
    let optionX = (this.containerWidth - this.containerOptionWidth) / 2;
    optionX += this.optionWidth * this.animationConfig.option.offsetX;
    return optionX;
  }

  get optionY(): number {
    let optionY = (this.containerHeight - this.containerOptionHeight) / 2;
    optionY += this.optionHeight * this.animationConfig.option.offsetY;
    return optionY;
  }

  mounted(): void {
    until(() => this.$refs.pixi).then(() => {
      const app = (this.$refs.pixi as any).app as PIXI.Application;
      app.ticker.add(() => TWEEDLE.Group.shared.update());
    });
    this.tween = new TWEEDLE.Tween(this.animationProps);
    this.tween
      .easing(TWEEDLE.Easing.Quadratic.InOut)
      .onComplete(() => this.animationend());

    if (this.animationConfig.foreground) {
      pixiUtil
        .loadTexture(
          `/assets/visualization/${this.animationConfig.foreground}.json`
        )
        .then((sheet) => {
          this.animationSprite = sheet;
          pixiUtil.convertSpritesheetToBase64(sheet, this.animationImages);
          const containerTexture = this.containerTexture;
          if (containerTexture) {
            this.textureWidth = containerTexture.width;
            this.textureHeight = containerTexture.height;
            this.calcSize();
          }
        });
    }
    window.addEventListener('resize', this.calcSize);
  }

  unmounted(): void {
    window.removeEventListener('resize', this.calcSize);
  }

  @Watch('answers', { immediate: true })
  async onAnswersChanged(): Promise<void> {
    await until(() => this.animationProps.ready);
    this.selectionList = [];
    if (this.$refs.approved) {
      (this.$refs.approved as any).currentFrame = 0;
    }
    if (this.$refs.rejected) {
      (this.$refs.rejected as any).currentFrame = 0;
    }
    this.storeList = [...this.answers];
    const colors = getRandomColorList(this.storeList.length);
    for (let i = 0; i < this.storeList.length; i++) {
      this.storeList[i].settings.color = colors[i];
      if (Array.isArray(this.animationConfig.option.image))
        this.storeList[i].settings.image = this.animationConfig.option.image[i];
      else this.storeList[i].settings.image = this.animationConfig.option.image;
    }
  }

  async calcSize(): Promise<void> {
    const dom = this.$refs.canvasContainer as HTMLElement;
    if (
      dom &&
      (dom.offsetWidth !== this.optionWidth ||
        dom.clientHeight !== this.optionHeight)
    ) {
      this.environmentWidth = dom.offsetWidth;
      this.environmentHeight = dom.clientHeight;
      let optionAspect = 1;
      if (this.animationSprite) {
        const optionImg = Array.isArray(this.animationConfig.option.image)
          ? this.animationConfig.option.image[0]
          : this.animationConfig.option.image;
        const optionTexture = this.animationSprite.textures[optionImg];
        optionAspect = optionTexture.width / optionTexture.height;
      }
      if (this.environmentAspect >= this.textureAspect) {
        this.containerHeight =
          this.environmentHeight * this.animationConfig.container.size;
        this.containerWidth = this.containerHeight * this.textureAspect;
        this.optionHeight =
          this.containerHeight * this.animationConfig.option.heightFactor;
        this.optionWidth = this.optionHeight * optionAspect;
      } else {
        this.containerWidth =
          this.environmentWidth * this.animationConfig.container.size;
        this.containerHeight = this.containerWidth / this.textureAspect;
        this.optionWidth =
          this.containerWidth * this.animationConfig.option.widthFactor;
        this.optionHeight = this.optionWidth / optionAspect;
      }
      this.optionAspect = this.optionWidth / this.optionHeight;
      this.isContainerSet = true;
      this.animationProps.backgroundPositionX = this.containerX;
    }
  }

  changeContainer(): void {
    if (this.selectionList.length > 4) {
      this.storeList.push(...this.selectionList.splice(4));
    }
    for (const item of this.storeList) {
      const choice = this.choices.find((choic) => choic.answer === item.id);
      if (choice) choice.result = false;
    }
    for (const item of this.selectionList) {
      const choice = this.choices.find((choic) => choic.answer === item.id);
      if (choice) choice.result = true;
    }
  }

  confirm(): void {
    this.$emit('confirm');
  }

  @Watch('showInfo', { immediate: true })
  onShowInfoChanged(): void {
    if (!this.animationConfig.result && this.showInfo) {
      this.startAnimation();
      if (this.showAnimation) {
        setTimeout(() => {
          this.$emit('update:showAnimation', false);
        }, 5000);
      }
    }
  }

  animationCompleted(): void {
    this.startAnimation();
    if (this.showAnimation) {
      setTimeout(() => {
        this.$emit('update:showAnimation', false);
      }, 5000);
    }
  }

  startAnimation(): void {
    this.animationProps.ready = false;
    if (this.tween) {
      this.tween
        .stop()
        .from({ backgroundPositionX: this.containerX })
        .to(
          {
            backgroundPositionX: this.isCorrect
              ? Math.round(this.environmentWidth)
              : Math.round(-this.containerWidth),
          },
          2000
        )
        .easing(TWEEDLE.Easing.Quadratic.In)
        .start();
    }
  }

  async animationend(): Promise<void> {
    this.animationProps.ready = true;
    const backgroundPositionX = Math.round(
      this.animationProps.backgroundPositionX
    );
    if (
      (backgroundPositionX === Math.round(this.environmentWidth) ||
        backgroundPositionX === Math.round(-this.containerWidth)) &&
      this.tween
    ) {
      this.updateAnimationCompleted();
      await delay(100);
      await until(() => this.selectionList.length === 0);
      this.tween
        .stop()
        .from({ backgroundPositionX: -this.containerWidth })
        .to({ backgroundPositionX: this.containerX }, 2000)
        .easing(TWEEDLE.Easing.Quadratic.Out)
        .start();
    }
  }

  updateAnimationCompleted(): void {
    this.$emit('animationCompleted');
  }

  @Watch('animateFinalStep', { immediate: true })
  onAnimateFinalStep(): void {
    if (this.animateFinalStep) {
      this.$emit('finalCompleted');
    }
  }
}
</script>

<style lang="scss" scoped>
.container-environment {
  background-color: var(--color-water);
  background-size: cover;
  background-position-x: center;
  width: 100%;
  height: 100%;

  .canvas-container {
    position: absolute;
  }

  .container {
    position: absolute;
    width: var(--containerWidth);
    height: var(--containerHeight);
    left: var(--containerX);
    top: var(--containerY);
    align-items: center;

    .container-store-space.horizontal {
      width: var(--containerOptionWidth);
      height: var(--containerOptionHeight);
      padding: var(--optionY) 0 0 var(--optionX);

      .title {
        display: none;
      }

      .option {
        width: var(--optionWidth);
        box-shadow: none;
        background-color: unset;
        border: none;
      }
    }

    .container-store-space.vertical {
      width: var(--containerOptionWidth);
      height: var(--containerOptionHeight);
      padding: var(--optionY) 0 0 var(--optionX);
    }

    .horizontal .container-store {
      display: inline-flex;
      align-items: center;
      gap: 0.2rem;
      width: 100%;
      height: 100%;
    }

    .vertical .container-store {
      width: 100%;
      height: 100%;
      font-size: 0.7rem;

      .title {
        margin: auto;
        padding-left: 0.3rem;
      }
    }
  }
}

.store {
}

.horizontal {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  min-height: var(--optionHeight);

  .image {
    display: inline-flex;
    background-color: var(--color);

    img {
      width: var(--optionWidth);
      height: var(--optionHeight);
      opacity: var(--opacity);
      filter: contrast(200%);
    }
  }

  .option {
    width: calc(var(--optionWidth) * var(--storeFactor));
    text-align: center;
    box-shadow: none;
    background-color: unset;
    border: none;
  }

  .el-card::v-deep(.el-card__body) {
    padding: 0;
  }

  .title {
    padding-bottom: 1rem;
  }
}

.vertical {
  .option {
    display: flex;
  }

  .el-card::v-deep(.el-card__body) {
    padding: 0;
    display: flex;
  }

  .image {
    display: inline-flex;
    background-color: var(--color);

    img {
      width: var(--optionWidth);
      height: var(--optionHeight);
      opacity: var(--opacity);
      filter: contrast(200%);
    }
  }

  .title {
    margin: auto;
    padding-left: 0.5rem;
    word-break: break-word;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.hint {
  padding-top: 1rem;
}
</style>
