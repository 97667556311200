<template>
  <quiz-question
    :question="question"
    :answers="answers"
    :showHint="showHint"
    :showInfo="showInfo"
    :isCorrect="isCorrect"
    :question-no="questionNo"
    :state-list="stateList"
    :can-confirm="canConfirm"
    @confirm="confirm"
  >
    <template #header>
      <visual-progress
        v-if="quiz"
        :visualizationType="visualizationType"
        :question-count="quiz.settings.count"
        v-model="animate"
        :answerKey="answerKey"
        :animate-final-step="animateFinalStep"
        @animationCompleted="animationCompleted"
        @finalCompleted="() => $emit('finalCompleted')"
      />
    </template>
    <template #default>
      <button
        v-for="(answer, index) in answers"
        :key="answer.id"
        @click="clickAnswer(index)"
      >
        <font-awesome-icon
          v-if="choices[index].result"
          :icon="['far', 'circle-check']"
        />
        <font-awesome-icon v-else :icon="['far', 'circle']" />
        <font-awesome-icon
          v-if="answer.settings.icon"
          :icon="answer.settings.icon"
        />
        {{ answer.title }}
        <div v-if="answer.description">
          {{ answer.description }}
        </div>
      </button>
    </template>
  </quiz-question>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Question } from '@/types/api/Question';
import { Answer } from '@/types/api/Answer';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Choice } from '@/types/api/Choice';
import VisualProgress from '@/components/VisualProgress.vue';
import { Quiz } from '@/types/api/Quiz';
import QuizQuestion from '@/components/QuizQuestion.vue';
import { QuestionState } from '@/types/quiz/QuestionState';
import { VehicleType } from '@/types/enum/VehicleType';

@Options({
  components: {
    QuizQuestion,
    VisualProgress,
    FontAwesomeIcon,
  },
  emits: [
    'confirm',
    'animationCompleted',
    'finalCompleted',
    'update:showAnimation',
  ],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class MultipleChoice extends Vue {
  @Prop() readonly quiz!: Quiz;
  @Prop() readonly question!: Question;
  @Prop({ default: [] }) readonly answers!: Answer[];
  @Prop({ default: [] }) readonly choices!: Choice[];
  @Prop({ default: false }) readonly showHint!: boolean;
  @Prop({ default: false }) readonly showAnimation!: boolean;
  @Prop({ default: false }) readonly showInfo!: boolean;
  @Prop({ default: false }) readonly isCorrect!: boolean;
  @Prop({ default: 0 }) readonly questionNo!: number;
  @Prop({ default: [] }) readonly stateList!: QuestionState[];
  @Prop({ default: false }) readonly canConfirm!: boolean;
  @Prop({ default: false }) readonly animateFinalStep!: boolean;

  get animate(): boolean {
    return this.showAnimation;
  }

  set animate(value: boolean) {
    this.$emit('update:showAnimation', value);
  }

  get correctCount(): number {
    let count = 0;
    for (const answer of this.answers) {
      if (answer.correct) count++;
    }
    return count;
  }

  get visualizationType() {
    return this.quiz.settings.visualization_type;
  }

  get answerKey(): VehicleType[] {
    const list: VehicleType[] = [];
    for (const answer of this.answers) {
      if (answer.correct) {
        if (answer.vehicle) list.push(answer.vehicle);
      }
    }
    return list;
  }

  clickAnswer(index: number): void {
    if (this.correctCount === 1) {
      for (let i = 0; i < this.choices.length; i++) {
        this.choices[i].result = i === index;
      }
    } else {
      this.choices[index].result = !this.choices[index].result;
    }
  }

  confirm(): void {
    this.$emit('confirm');
  }
  animationCompleted(): void {
    this.$emit('animationCompleted');
  }
}
</script>

<style lang="scss" scoped>
button {
  display: block;
  font-size: var(--font-size-default);
  margin-bottom: 0.5rem;
  width: 100%;

  svg {
    margin-right: 1rem;
  }
}
</style>
