import { readJSON } from '@/utils/json';
import * as authService from '@/services/auth-service';

const quizEn = readJSON('/assets/data/quiz-en.json');
const quizDe = readJSON('/assets/data/quiz-de.json');

/* eslint-disable @typescript-eslint/no-explicit-any*/
export function getQuizJson(language: string | null = null): any {
  if (!language) language = authService.getLocale();
  switch (language) {
    case 'de':
      return quizDe;
    case 'en':
      return quizEn;
  }
  return quizDe;
}
