import { QuestionResult } from '@/types/api/QuestionResult';

export async function postQuestionResult(
  result: QuestionResult
): Promise<QuestionResult> {
  //console.log('post', result);
  return result;
}

export async function putQuestionResult(
  result: QuestionResult
): Promise<QuestionResult> {
  //console.log('put', result);
  return result;
}
