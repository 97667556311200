export enum QuizType {
  WATERWAY = 'waterway',
  SHIPPING = 'shipping',
  CLIMATE = 'climate',
  TRANSITION = 'transition',
  FACTORS = 'factors',
  PROCUREMENT = 'procurement',
  LAW = 'law',
  PORTS = 'ports',
}
