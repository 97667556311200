<template>
  <el-select v-model="language" @change="parseExcel">
    <el-option value="de">de</el-option>
    <el-option value="en">en</el-option>
  </el-select>
  <el-upload
    drag
    action="#"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :http-request="uploadFile"
    accept=".xlsx"
    class="el-upload"
  >
    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    <div class="el-upload__text">
      {{ $t('views.parse.drag') }}
    </div>
    <template #tip>
      <div class="el-upload__tip">
        {{ $t('views.parse.tip') }}
      </div>
    </template>
  </el-upload>
  <a
    v-if="downloadJson"
    :download="`quiz-${language}.json`"
    :href="downloadJson"
  >
    {{ $t('views.parse.download') }}
  </a>
  <div class="json">
    {{ json }}
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import * as excelService from '@/services/excel-service';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { UploadFilled } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';

@Options({
  components: { FontAwesomeIcon, UploadFilled },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class ParseInput extends Vue {
  json = '';
  language = 'de';
  file: File | null = null;
  downloadJson = '';

  mounted(): void {
    this.parseExcel();
  }

  parseExcel(): void {
    if (this.file) {
      excelService.importFromUpload(this.file, this.language).then((json) => {
        this.json = JSON.stringify(json, null, '\t');
        this.downloadJson = this.parseToFile();
      });
    } /*else {
      excelService.importFromExcel(this.language).then((json) => {
        this.json = JSON.stringify(json, null, '\t');
        this.downloadJson = this.parseToFile();
      });
    }*/
  }

  parseToFile(): string {
    const fileToSave = new Blob([this.json], {
      type: 'text/json',
    });
    return URL.createObjectURL(fileToSave);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadFile(res: any): boolean {
    this.file = res.file;
    this.parseExcel();
    return true;
  }

  beforeUpload(file: any): boolean {
    if (file.name.endsWith('.xlsx')) {
      return true;
    }
    ElMessage.error((this as any).$t('views.parse.wrongType'));
    return false;
  }
}
</script>

<style lang="scss" scoped>
.json {
  white-space: pre-wrap;
}

.el-upload {
  display: inline-block;
  --image-size: 122px;
  text-align: left;
  width: 100%;
}

.el-upload::v-deep(.el-upload) {
  width: 100%;
}

.el-upload::v-deep(.el-upload-dragger) {
  width: unset;
  height: unset;
  padding: 2rem;
}
</style>
