async function appViewport(): Promise<void> {
  const doc = document.documentElement;
  const viewport = document.querySelector('meta[name=viewport]');
  const outerWidth = document.documentElement.scrollWidth;
  const outerHeight = document.documentElement.scrollHeight;
  const width = window.innerWidth < outerWidth ? window.innerWidth : outerWidth;
  const height =
    window.innerHeight < outerHeight ? window.innerHeight : outerHeight;

  doc.style.setProperty('--app-height', `${height}px`);
  doc.style.setProperty('--app-width', `${width}px`);
  document.body.style.height = `${height}px`;

  setTimeout(() => {
    if (viewport) {
      let viewportContent = `height=${height},width=${width}`;
      const props = viewport.getAttribute('content')?.split(',');
      if (props) {
        for (const prop of props) {
          if (!prop.startsWith('width=') && !prop.startsWith('height=')) {
            viewportContent += `,${prop}`;
          }
        }
      }
      viewport.setAttribute('content', viewportContent);
    }
  }, 300);
}

const setViewportVariables = (): void => {
  window.addEventListener('resize', appViewport);
  appViewport();
};

export default setViewportVariables;
