import { jwtDecode } from 'jwt-decode';
import { useCookies } from 'vue3-cookies';
import { VueI18n } from 'vue-i18n';
const { cookies } = useCookies();

const JWT_KEY = 'jwt';
const LOCALE = 'locale';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export const isAuthenticated = (): boolean => {
  const jwtFromStorage = getAccessToken();
  return !!jwtFromStorage;
};

const getAuthorisationProperty = (propertyName: string): any => {
  const jwtFromStorage = getAccessToken();
  if (jwtFromStorage != null) {
    const decoded = jwtDecode(jwtFromStorage) as any;
    if (decoded && propertyName in decoded) {
      return decoded[propertyName];
    }
  }
  return null;
};

export const getUserId = (): string => {
  return getAuthorisationProperty('userId') as string;
};

export const getAccessToken = (): string | null => {
  return cookies.get(JWT_KEY);
};

export const setAccessToken = (jwt: string): void => {
  cookies.set(JWT_KEY, 'Bearer ' + jwt);
};

export const removeAccessToken = (): void => {
  //cookies.remove(JWT_KEY);
};

export const setLocale = (
  locale: string,
  i18n: VueI18n | null = null
): void => {
  if (cookies) cookies.set(LOCALE, locale);
  if (i18n) i18n.locale = locale as any;
};

export const getLocale = (): string => {
  if (cookies) return cookies.get(LOCALE);
  return '';
};
