<template>
  <div class="header" v-if="$slots.header">
    <slot name="header" />
  </div>
  <div class="timeline">
    <el-steps :active="questionNo">
      <el-step
        v-for="(item, index) in stateList"
        :key="index"
        :status="getStatus(item)"
      />
    </el-steps>
  </div>
  <div class="question" v-if="!showInfo && question">
    <h2>{{ question.title }}</h2>
    <div class="count" v-if="correctList.length > 1">
      {{ $t('views.quiz.multipleAnswers') }}
    </div>
    <div v-if="question.description">
      {{ question.description }}
    </div>
    <div class="hint" v-if="showHint">
      {{ question.hint }}
    </div>
    <div class="content">
      <slot />
    </div>
    <button :disabled="!canConfirm" @click="confirm" class="confirm">
      {{ $t('views.quiz.confirm') }}
    </button>
  </div>
  <div class="info" v-else-if="question">
    <h3 class="correct" v-if="isCorrect">
      {{ $t('views.quiz.correct') }}
    </h3>
    <h3 class="wrong" v-else>
      {{ $t('views.quiz.wrong') }}
      <ul>
        <li v-for="item of correctList" :key="item.id">{{ item.title }}</li>
      </ul>
    </h3>
    <div class="explanation">
      {{ question.explanation }}
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Question } from '@/types/api/Question';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { QuestionState } from '@/types/quiz/QuestionState';
import { Answer } from '@/types/api/Answer';

@Options({
  components: {
    FontAwesomeIcon,
  },
  emits: ['confirm'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class QuizQuestion extends Vue {
  @Prop() readonly question!: Question;
  @Prop({ default: 0 }) readonly questionNo!: number;
  @Prop({ default: [] }) readonly stateList!: QuestionState[];
  @Prop({ default: [] }) readonly answers!: Answer[];
  @Prop({ default: false }) readonly showHint!: boolean;
  @Prop({ default: false }) readonly showInfo!: boolean;
  @Prop({ default: false }) readonly isCorrect!: boolean;
  @Prop({ default: false }) readonly canConfirm!: boolean;

  confirm(): void {
    this.$emit('confirm');
  }

  getStatus(state: QuestionState): string {
    if (!state.isAnswered) return 'wait';
    if (state.isCorrect) return 'success';
    return 'error';
  }

  get correctList(): Answer[] {
    return this.answers.filter((item) => item.correct);
  }
}
</script>

<style lang="scss" scoped>
.timeline {
  padding: 1rem 1rem 0 1rem;
}

.question,
.info {
  padding: 1rem;

  h2 {
    font-size: var(--font-size-large);
  }
}

button {
  display: block;
  font-size: var(--font-size-default);
  margin-bottom: 0.5rem;
  width: 100%;
}

button.confirm:disabled {
  background-color: var(--color-main-light);
  color: var(--color-background);
}

.confirm {
  background-color: var(--color-main);
  color: var(--color-background);
  margin-top: 1rem;
}

.header {
  width: 100vw;
  height: min(30vh, 50vw);
}

.correct {
  color: var(--el-color-success);
}

.wrong {
  color: var(--el-color-error);
}

.hint {
  font-size: var(--font-size-emphasize);
  padding-bottom: 1rem;
}

.count {
  padding-bottom: 1rem;
}
</style>
