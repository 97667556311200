import { createApp } from 'vue';
import VueCookies from 'vue3-cookies';
import setViewportVariables from '@/utils/vunit';
import App from './App.vue';
import router from './router';
import * as i18n from '@/i18n';

import '@/assets/styles/global.scss';
import ElementPlus from 'element-plus';
import '@/assets/styles/element-plus.scss';

/* eslint-disable @typescript-eslint/no-explicit-any*/

import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fac } from '@/assets/icon/fac';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas as any);
library.add(far as any);
library.add(fac as any);

const app = createApp(App);
app.use(router);
app.use(VueCookies as any, {
  expireTimes: '356d',
  secure: true,
  sameSite: 'Strict', // "Lax"
});
const i18nOutput = i18n.publicI18n();
app.use(i18nOutput as any);
app.use(ElementPlus as any);
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
setViewportVariables();

export default app;
