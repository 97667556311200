import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-422b7988"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-upload__text" }
const _hoisted_2 = { class: "el-upload__tip" }
const _hoisted_3 = ["download", "href"]
const _hoisted_4 = { class: "json" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_upload_filled = _resolveComponent("upload-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_select, {
      modelValue: _ctx.language,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.language) = $event)),
      onChange: _ctx.parseExcel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_option, { value: "de" }, {
          default: _withCtx(() => [
            _createTextVNode("de")
          ]),
          _: 1
        }),
        _createVNode(_component_el_option, { value: "en" }, {
          default: _withCtx(() => [
            _createTextVNode("en")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onChange"]),
    _createVNode(_component_el_upload, {
      drag: "",
      action: "#",
      "show-file-list": false,
      "before-upload": _ctx.beforeUpload,
      "http-request": _ctx.uploadFile,
      accept: ".xlsx",
      class: "el-upload"
    }, {
      tip: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('views.parse.tip')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
          default: _withCtx(() => [
            _createVNode(_component_upload_filled)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('views.parse.drag')), 1)
      ]),
      _: 1
    }, 8, ["before-upload", "http-request"]),
    (_ctx.downloadJson)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          download: `quiz-${_ctx.language}.json`,
          href: _ctx.downloadJson
        }, _toDisplayString(_ctx.$t('views.parse.download')), 9, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.json), 1)
  ], 64))
}