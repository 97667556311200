import { createI18n, I18n } from 'vue-i18n';
import { readJSON } from '@/utils/json';
import * as authService from '@/services/auth-service';

/* eslint-disable @typescript-eslint/no-explicit-any*/

const customI18n = (messages: any): I18n => {
  const availableLocales = Object.keys(messages);
  let language = authService.getLocale();
  if (!language) {
    language = navigator.language.substring(0, 2);
    language =
      language && availableLocales.includes(language)
        ? language
        : process.env.VUE_APP_I18N_LOCALE || 'de';
    authService.setLocale(language as string);
  }
  return createI18n({
    locale: language,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    messages,
    availableLocales,
    legacy: false,
  });
};

export function publicI18n(): I18n {
  const en = readJSON('/assets/locales/en.json');
  const de = readJSON('/assets/locales/de.json');
  return customI18n({ de, en });
}
