import { Quiz } from '@/types/api/Quiz';
import settings from '@/assets/data/settings.json';
import { getQuizJson } from '@/services/json-service';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export async function getQuizList(
  language: string | null = null
): Promise<Quiz[]> {
  const config = getQuizJson(language);
  const keys = Object.keys(settings);
  const topicIndex: { [key: string]: number } = {};
  for (let i = 0; i < keys.length; i++) {
    topicIndex[keys[i]] = i;
  }
  return (config as any)
    .map((item) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        topic: item.topic,
        info_link: item.info_link,
        settings: settings[item.topic],
      };
    })
    .sort((a, b) => topicIndex[a.topic] - topicIndex[b.topic]);
}

export async function getQuiz(
  id: number,
  language: string | null = null
): Promise<Quiz> {
  const config = getQuizJson(language);
  let item = (config as any).find((item) => item.id === id);
  if (!item) item = config[0];
  return {
    id: id,
    title: item.title,
    description: item.description,
    topic: item.topic,
    info_link: item.info_link,
    settings: settings[item.topic],
  } as Quiz;
}

export function setIDs(
  resetAllIds = false,
  language: string | null = null
): void {
  const config = getQuizJson(language);
  /*for (const quiz of config as any) {
    if (!quiz.id) quiz.id = uuidv4();
    for (const question of quiz.questions as any) {
      if (!question.id) question.id = uuidv4();
      for (const answer of question.options as any) {
        if (!answer.id) answer.id = uuidv4();
      }
    }
  }*/

  let questionId = 0;
  let answerId = 0;
  for (let quizId = 0; quizId < config.length; quizId++) {
    const quiz = config[quizId] as any;
    if (!quiz.id || isNaN(quiz.id as any) || resetAllIds) quiz.id = quizId;
    for (const question of quiz.questions as any) {
      if (!question.id || isNaN(question.id as any) || resetAllIds)
        question.id = questionId;
      for (const answer of question.options as any) {
        if (!answer.id || isNaN(answer.id as any) || resetAllIds)
          answer.id = answerId;
        answerId++;
      }
      questionId++;
    }
  }
  console.log(JSON.stringify(config));
}
