import * as PIXI from 'pixi.js';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export async function loadTexture(url: string): Promise<any> {
  if (PIXI.Cache.has(url)) {
    const texture = url.endsWith('.json')
      ? PIXI.Cache.get(url)
      : PIXI.Assets.get(url);
    const isValid = !Object.hasOwn(texture, 'valid') || texture.valid;
    const hasBaseTexture =
      !Object.hasOwn(texture, 'baseTexture') || !!texture.baseTexture?.resource;
    if (isValid && hasBaseTexture) return texture;
    else {
      await unloadTexture(url);
    }
  }

  try {
    return await PIXI.Assets.load(url);
  } catch (e) {
    return null;
  }
}

export async function unloadTexture(url: string): Promise<void> {
  if (PIXI.Cache.has(url)) {
    await PIXI.Assets.unload(url);
  }
}

export function getSpriteAspect(
  spritesheet: PIXI.Spritesheet,
  spriteName: string
): number {
  if (spritesheet?.data?.frames && spriteName in spritesheet.data.frames) {
    const h = spritesheet.data.frames[spriteName].sourceSize?.h;
    const w = spritesheet.data.frames[spriteName].sourceSize?.w;
    if (h && w) return w / h;
  }
  return 1;
}

export function getTextureAspect(texture: PIXI.Texture | null): number {
  if (texture) {
    const h = texture.orig.height;
    const w = texture.orig.width;
    if (h && w) return w / h;
  }
  return 1;
}

export async function convertTextureToBase64(
  texture: PIXI.Texture,
  renderer: PIXI.Renderer | null = null
): Promise<string> {
  const graphics = new PIXI.Graphics()
    .beginTextureFill({
      texture: texture,
    })
    .drawRect(0, 0, texture.width, texture.height);
  if (!renderer || !renderer.extract || !(renderer.extract as any).renderer)
    renderer = fallbackRenderer;
  return await renderer.extract.base64(graphics as PIXI.DisplayObject);
}

const fallbackRenderer = new PIXI.Renderer();
export async function convertSpritesheetToBase64(
  sheet: PIXI.Spritesheet,
  result: { [key: string]: string } = {},
  renderer: PIXI.Renderer | null = null
): Promise<{ [key: string]: string }> {
  if (!renderer) renderer = fallbackRenderer;
  for (const textureKey of Object.keys(sheet.textures)) {
    if (sheet.textures) {
      const texture = sheet.textures[textureKey];
      result[textureKey] = await convertTextureToBase64(texture, renderer);
    }
  }
  return result;
}
