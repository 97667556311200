import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sprite = _resolveComponent("sprite")!
  const _component_animated_sprite = _resolveComponent("animated-sprite")!
  const _component_container = _resolveComponent("container")!
  const _component_Application = _resolveComponent("Application")!

  return (_openBlock(), _createBlock(_component_Application, {
    ref: "pixi",
    width: _ctx.width,
    height: _ctx.height,
    transparent: true,
    backgroundAlpha: 0,
    style: { 'touch-action': 'auto' }
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.texture)
        ? (_openBlock(), _createBlock(_component_container, { key: 0 }, {
            default: _withCtx(() => [
              (!_ctx.isAnimation && !Array.isArray(_ctx.texture))
                ? (_openBlock(), _createBlock(_component_sprite, {
                    key: 0,
                    texture: _ctx.texture,
                    width: _ctx.objectWidth * _ctx.scaleFactor,
                    height: _ctx.objectHeight * _ctx.scaleFactor,
                    tint: _ctx.tint,
                    anchor: 0.5,
                    x: _ctx.width / 2,
                    y: _ctx.height / 2
                  }, null, 8, ["texture", "width", "height", "tint", "x", "y"]))
                : (_ctx.isAnimationLoaded)
                  ? (_openBlock(), _createBlock(_component_animated_sprite, {
                      key: 1,
                      textures: _ctx.animationSequence,
                      "animation-speed": _ctx.animationSpeed,
                      width: _ctx.objectWidth * _ctx.scaleFactor,
                      height: _ctx.objectHeight * _ctx.scaleFactor,
                      anchor: 0.5,
                      tint: _ctx.tint,
                      x: _ctx.width / 2,
                      y: _ctx.height / 2,
                      onRender: _ctx.renderAnimation,
                      onFrameChange: _ctx.animationFrameChanged
                    }, null, 8, ["textures", "animation-speed", "width", "height", "tint", "x", "y", "onRender", "onFrameChange"]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "overlay")
    ]),
    _: 3
  }, 8, ["width", "height"]))
}