import { Choice } from '@/types/api/Choice';
//import { read, utils, writeXLSX } from 'xlsx';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export async function postChoice(choice: Choice): Promise<Choice> {
  /*const f = await fetch('/assets/data/log.xlsx', {
    method: 'GET',
    withCredentials: true,
    crossorigin: true,
    mode: 'no-cors',
  } as any);
  const ab = await f.arrayBuffer();

  const wb = read(ab);
  const data: Choice[] = utils.sheet_to_json<Choice>(wb.Sheets['choice']);
  data.push(choice);
  console.log(wb, wb.Sheets['choice'], data);
  wb.Sheets['choice'] = utils.json_to_sheet(data);
  const wbOut = writeXLSX(wb, { bookType: 'xlsx', type: 'base64' });

  const formData = new FormData();
  formData.append('file', 'test.xlsx'); // <-- server expects `file` to hold name
  formData.append('data', wbOut); // <-- `data` holds the data encoded in Base64

  await fetch('/assets/data/log.xlsx', {
    method: 'POST',
    body: formData,
    withCredentials: true,
    crossorigin: true,
    mode: 'no-cors',
  } as any);*/
  //console.log('post', choice);
  return choice;
}

export async function putChoice(choice: Choice): Promise<Choice> {
  //console.log('put', choice);
  return choice;
}
