import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sprite = _resolveComponent("sprite")!
  const _component_animated_sprite = _resolveComponent("animated-sprite")!
  const _component_container = _resolveComponent("container")!
  const _component_Application = _resolveComponent("Application")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "canvasContainer",
    class: "canvasContainer",
    style: _normalizeStyle({
      backgroundColor: _ctx.animationConfig.backgroundColor,
      backgroundImage: _ctx.animationConfig.background
        ? `url(/assets/animation/${_ctx.animationConfig.background})`
        : 'none',
      '--x': `${_ctx.animationProps.backgroundPositionX}px`,
    })
  }, [
    (_ctx.animationConfig.overlay)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "backgroundOverlay",
          style: _normalizeStyle({
        width: `${_ctx.containerWidth}px`,
        height: `${_ctx.containerHeight}px`,
        backgroundImage: _ctx.animationConfig.overlay
          ? `url(/assets/animation/${_ctx.animationConfig.overlay})`
          : 'none',
        '--percent': `${_ctx.overlayPercent}%`,
      })
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.isLoaded && _ctx.isContainerSet)
      ? (_openBlock(), _createBlock(_component_Application, {
          key: 1,
          ref: "pixi",
          width: _ctx.containerWidth,
          height: _ctx.containerHeight,
          transparent: true,
          backgroundAlpha: 0,
          sortableChildren: true
        }, {
          default: _withCtx(() => [
            (_ctx.animationConfig.backgroundSprite)
              ? (_openBlock(), _createBlock(_component_sprite, {
                  key: 0,
                  texture: _ctx.animationSprite.textures[_ctx.animationConfig.backgroundSprite],
                  width: _ctx.getObstacleWidth(),
                  height: _ctx.getObstacleHeight(),
                  x: _ctx.getObstacleX() + _ctx.animationProps.backgroundPositionX,
                  y: _ctx.getObstacleY(),
                  "anchor-x": _ctx.animationConfig.stepAnchorX,
                  "anchor-y": _ctx.animationConfig.stepAnchorY
                }, null, 8, ["texture", "width", "height", "x", "y", "anchor-x", "anchor-y"]))
              : _createCommentVNode("", true),
            _createVNode(_component_container, { sortableChildren: true }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepCount, (stepIndex) => {
                  return (_openBlock(), _createBlock(_TransitionGroup, { key: stepIndex }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFrameCount(stepIndex - 1), (frameIndex) => {
                        return (_openBlock(), _createBlock(_component_animated_sprite, {
                          key: frameIndex,
                          textures: _ctx.getObstacleAnimation(stepIndex - 1, frameIndex - 1),
                          "animation-speed": 0.1,
                          width: _ctx.getObstacleWidth(stepIndex - 1, frameIndex - 1),
                          height: _ctx.getObstacleHeight(stepIndex - 1, frameIndex - 1),
                          x: 
              _ctx.getObstacleX(stepIndex - 1, frameIndex - 1) +
              _ctx.animationProps.backgroundPositionX
            ,
                          y: _ctx.getObstacleY(stepIndex - 1, frameIndex - 1),
                          zIndex: _ctx.getObstacleZIndex(stepIndex - 1, frameIndex - 1),
                          "anchor-x": _ctx.animationConfig.stepAnchorX,
                          "anchor-y": _ctx.animationConfig.stepAnchorY,
                          loop: false,
                          playing: stepIndex <= _ctx.animationProps.index,
                          onComplete: _ctx.obstacleCompleted
                        }, null, 8, ["textures", "width", "height", "x", "y", "zIndex", "anchor-x", "anchor-y", "playing", "onComplete"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vehicles, (vehicle, index) => {
                  return (_openBlock(), _createBlock(_component_animated_sprite, {
                    key: index,
                    textures: _ctx.getCurrentVehicleAnimation(vehicle, index),
                    "animation-speed": 0.1,
                    width: _ctx.getSpriteWidth(vehicle),
                    height: _ctx.getSpriteHeight(vehicle),
                    x: _ctx.getForegroundX(index),
                    y: _ctx.getSpriteY(vehicle),
                    zIndex: 1,
                    "anchor-x": 1,
                    "anchor-y": 1,
                    loop: _ctx.animationProps.foreground[index].tween?.isPlaying(),
                    playing: _ctx.animationProps.foreground[index].tween?.isPlaying(),
                    onLoop: () => _ctx.vehicleLoop(index)
                  }, null, 8, ["textures", "width", "height", "x", "y", "loop", "playing", "onLoop"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["width", "height"]))
      : _createCommentVNode("", true)
  ], 4))
}