import { QuizResult } from '@/types/api/QuizResult';
import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

const QUIZ_RESULT_KEY = 'quiz_result';

export async function postResult(
  playerKey: string,
  quizId: number,
  correct: number,
  count: number,
  hints: number,
  stars: number
): Promise<QuizResult> {
  const result: QuizResult = {
    id: null,
    quiz: quizId,
    player_key: playerKey,
    correct: correct,
    count: count,
    hints: hints,
    stars: stars,
    timestamp: new Date(),
  };
  let resultList = [] as QuizResult[];
  const store = localStorage[QUIZ_RESULT_KEY] ?? cookies.get(QUIZ_RESULT_KEY);
  if (store) resultList = JSON.parse(store) as QuizResult[];
  resultList.push(result);
  localStorage.setItem(QUIZ_RESULT_KEY, JSON.stringify(resultList));
  return result;
}

export async function getResultList(quizId: number): Promise<QuizResult[]> {
  let resultList = [] as QuizResult[];
  const store = localStorage[QUIZ_RESULT_KEY] ?? cookies.get(QUIZ_RESULT_KEY);
  if (store) resultList = JSON.parse(store) as QuizResult[];
  return resultList.filter((item) => item.quiz === quizId);
}
